a:hover {
  text-decoration: none !important;
}

.comingSoon span {
  color: pink;
}

.pools_home {
  color: #fff !important;
}

.btn {
  padding: 7px 25px !important;
  color: #000 !important;
}

.whitepaper_btn {
  /* margin-left: 15px; */
  color: rgb(44, 43, 43) !important;
  border: none !important;
  outline: none !important;
  text-decoration: none !important;
  font-weight: bold;
}

.SjnjMjj {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.SjnjMjj .homeBtn {
  width: 150px;
  height: 40px;
  border-radius: 7px;
  color: #fff;
  font-weight: 500;
  background: linear-gradient(95.08deg, #9F2DFE 6.71%, #3BB2F9 111.09%);
  padding: 8px;
}

.SjnjMjj .sanjjjjjj{
  width: 150px;
}


[data-rk] ._12cbo8i6, [data-rk] ._12cbo8i6::after {
  background: var(--gr-primary) !important;
  border-radius: 8px;
}

.btn_extra {
  padding: 8px 50px !important;
  border-radius: 8px;
  background: #fff;
  margin-left: 0 !important;
  border: none !important;
  outline: none !important;
  text-decoration: none !important;
}

.nft_btn {
  background: var(--gr-primary) !important;
  border-radius: 8px;
  /* margin-left: 15px; */
  padding: 8px 50px !important;
  border: none !important;
  outline: none !important;
  text-decoration: none !important;
}

.info_token div {
  min-height: 130px !important;
  padding: 30px 10px !important;
}


.banner-area img {
  margin-top: 30px;
}


.call-to-action-padding {
  border-radius: 8px !important;
}

button {
  text-decoration: none;
  color: #fff !important;
  cursor: pointer;
}

.feature_box {
  margin-top: 30px;
}

.amount_input {
  margin-bottom: 5px;
  width: 100%;

}

.threeandfourth {
  margin-top: 20px;
}


@media(min-width:777px) {


  .info_token {
    margin-top: 20px;
  }

  .info_token {
    max-width: 25% !important;
  }
}

.desktop-buy-button {
  margin-right: 10px;
}

.buyBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(95.08deg, #9F2DFE 6.71%, #3BB2F9 111.09%);
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  padding: 9px !important;
  padding-left: 7px !important;
  padding-right: 8px !important;
  cursor: pointer;
  gap: 5px;
  border: none !important;
  width: 150px;
}

.buyBtn img {
  width: 20px;
}

.mobile-buy-button {
  display: none;
}

@media screen and (max-width: 991px) {
  .mobile-buy-button {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .desktop-buy-button {
    display: none !important;
  }
}

/* 
.sc-gSAPjG.dehEGZ {
  display: flex !important;
  flex-wrap: wrap !important;
  max-width: 1000px;
  margin: auto;
} */

.partnersImg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px !important;
  margin: auto;
  gap: 30px;
  margin-bottom: 100px;
  margin-top: 20px;
}

.partnersImg div {
  width: 130px;
}

.partnersImg div img {
  width: 100%;
}

.partnersImg div:nth-child(1) {
  width: 55px;
}

.partnersImg div:nth-child(2) {
  width: 50px;
}

.partnersImg div:nth-child(3) {
  width: 50px;
}

.partnersImg div:nth-child(4) {
  width: 50px;
}

.partnersImg div:nth-child(7) {
  width: 150px;
}

.partnersImg div:nth-child(8) {
  width: 50px;
}

.partnersImg div:nth-child(11) {
  width: 170px;
}

.partnersImg div:nth-child(12) {
  width: 170px;
}

.partnersImg div:nth-child(13) {
  width: 170px;
}

.partnersImg div:nth-child(14) {
  width: 170px;
}

.partnersImg div:nth-child(15) {
  width: 180px;
}

.partnersImg div:nth-child(16) {
  width: 180px;
}

.trendingSect {
  display: flex;
  flex-direction: column;
}

.hero-area-buttons {
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.hero-area-buttons a {
  max-width: 100%;
}

.coundownBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 10px;
}

.coundownBox div {
  background-color: #fff;
  border-radius: 20%;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
}

.coundownBox div span {
  font-size: 40px;
}

@media screen and (max-width: 550px) {
  .coundownBox {
    gap: 10px;
  }
}

.footer-info-social {
  margin-bottom: 30px;
}

.safu_btn {
  margin-bottom: 20px;
}

.safu_btn:hover {
  background-color: #c2c2c2 !important;
}

.safu_btn .icon {
  color: green;
  font-size: 20px;
}

.pancakeImg {
  width: 20px;
  margin-left: 5px;
}

.cu-form__scroll .cu-form__container {
  background-color: red !important;
}

.Kjm {
  padding-top: 30px;
  /* border: 1px solid red; */
  /* background-color: rgb(212, 212, 212); */
}

.kkddd {
  width: 100% !important;
  height: 100vh !important;
}

[data-rk] .ju367v2b{
  width: 150px !important;
  text-align: center !important;
}

@media screen and (max-width: 501px) {
  /* .SjnjMjj{
    max-width: 200px;
  } */
  .SjnjMjj a:last-child{
    width: 310px;
  }
}